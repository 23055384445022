<template>
    <div>
      <h1>hhhhh</h1>
      <table>
        <thead>
          <tr>
            <th>价格类型</th>
            <th>售价 ($)</th>
            <th>利润 ($)</th>
            <th>利润率 (%)</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(price, index) in prices" :key="index">
            <td>{{ price.label }}</td>
            <td>
              <input type="number" v-model="price.sellingPrice">
            </td>
            <td>{{ calculateProfit(price.sellingPrice).toFixed(2) }}</td>
            <td>
              <input type="number" v-model.number="price.profitMargin" @input="updateSellingPrice(index)">
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        cost: 10,  // 成本固定为 $10
        prices: [
          { label: '零售价', sellingPrice: 20, profitMargin: 0 },
          { label: '一级价', sellingPrice: 15, profitMargin: 0 },
          { label: '二级价', sellingPrice: 12, profitMargin: 0 },
          { label: '三级价', sellingPrice: 11, profitMargin: 0 }
        ]
      };
    },
    methods: {
      calculateProfit(sellingPrice) {
        return sellingPrice - this.cost;
      },
      updateSellingPrice(index) {
        const price = this.prices[index];
        const newSellingPrice = ((price.profitMargin / 100) * this.cost) + this.cost;
        price.sellingPrice = parseFloat(newSellingPrice.toFixed(2));
      }
    },
    mounted() {
      // 初始化利润率
      console.log(1111)
      this.prices.forEach((price, index) => {
        this.prices[index].profitMargin = ((this.calculateProfit(price.sellingPrice) / this.cost) * 100).toFixed(2);
      });
    }
  };
  </script>
  